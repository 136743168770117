<template>
  <div class="headassembly">
    <div class="headassembly_left">

      <h2>
        <img src="../assets/image/yyt.png" alt="">
<!--        <img src="../assets/image/yyt.png" alt="">-->
        <p>智慧科研信息管理系统</p>
      </h2>
      <div class="headassembly_div" v-show="$route.path =='/HomePage' || $route.path =='/ProjectManagementPage' || $route.path == '/Systemsettings'|| $route.path == '/Allstatistics'">
        <ul>
          <li @click="aa" :class="{headassemblybackground:menuindex==1}">
            <img src="../assets/image/shou.png">
            <p>首页</p>
          </li>
          <li @click="hh" :class="{headassemblybackground:menuindex==7}">
            <img src="../assets/image/taskmanagement/projectList.png">
            <p>项目概览</p>
          </li>
          <li @click="ee" :class="{headassemblybackground:menuindex==6}" v-show="Type == 0">
            <img src="../assets/image/xiton.png">
            <p>系统管理</p>
          </li>
        </ul>
      </div>
      <div class="headassembly_div" v-show="$route.path !=='/HomePage'&& $route.path !=='/ProjectManagementPage'&& $route.path !=='/Systemsettings'&& $route.path !=='/Allstatistics'">
        <ul>
          <li @click="aa" :class="{headassemblybackground:menuindex==1}">
            <img src="../assets/image/shou.png">
            <p>首页</p>
          </li>
          <li @click="hh" :class="{headassemblybackground:menuindex==7}" >
            <img src="../assets/image/taskmanagement/projectList.png">
            <p>项目概览</p>
          </li>
          <li @click="bb" :class="{headassemblybackground:menuindex==2}" v-show="projectId&&project">
            <img src="../assets/image/xmgl.png">
            <p>项目管理</p>
          </li>
          <li @click="cc" :class="{headassemblybackground:menuindex==3}" v-show="projectId&&task">
            <img src="../assets/image/renwu.png">
            <p>任务管理</p>
          </li>
          <li @click="dd" :class="{headassemblybackground:menuindex==4}" v-show="projectId&&Finance">
            <img src="../assets/image/caiwu.png">
            <p>财务管理</p>
          </li>
          <li @click="ff" :class="{headassemblybackground:menuindex==5}" v-show="projectId">
            <img src="../assets/image/dangan.png">
            <p>档案管理</p>
          </li>
          <!-- <li @click="ee" :class="{headassemblybackground:menuindex==6}" v-show="Type == 0">
            <img src="../assets/image/xiton.png">
            <p>系统管理</p>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="headassembly_right">
      <div style="margin-top: 8px;margin-left: 200px">
        <el-dropdown>
                <span class="el-dropdown-link" style="font-size: 16px">
                    切换字体大小<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item  @click.native="changeFontSize('0')">小</el-dropdown-item>
            <el-dropdown-item  @click.native="changeFontSize('1')">中</el-dropdown-item>
            <el-dropdown-item  @click.native="changeFontSize('2')">大</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="margin-right: 20px">
        <el-button type="info" plain style="width: 128px;height: 36px;border-radius: 138px;position: relative" @click="Switchingsystem" disabled>
          <img src="../assets/image/notice/qiehuanhui.png" alt="" style="position: absolute;left: 20px;bottom: 8px">
          <span style="bottom: 8px;position: absolute;right: 20px;font-size: 16px;color: #909399">随访系统</span>
        </el-button>
      </div>
      <div style="margin-top: 8px;margin-left:2px;display: flex;cursor:pointer;" @click="msg1">
        <img src="../assets/image/notice/lingdang.png" alt="" style="width: 18px;height: 18px;margin-top: 2px">
        <div class="radio-dian" v-show="val > 0"></div>
      </div>
      <div style="margin-top: 10px;cursor:pointer;" @click="setting" v-if="Type == 0">
        <img src="../assets/image/notice/setting.png" alt="" style="width: 18px;height: 18px">
      </div>
      <div style="margin-top: 10px" v-else>
        <img src="../assets/image/notice/setting.png" alt="" style="width: 18px;height: 18px">
      </div>
      <div style="margin-right: 40px;margin-top: 5px">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link" style="display: flex;margin-bottom: 4px">
            <img src="../assets/image/notice/userImg.png" alt="" style="margin-right: 5px">
            <div style="margin-top: 2px">
               <span style="font-size: 16px;margin-bottom: 10px;">{{ UserName |ellipsis}}</span
               ><i class="el-icon-caret-bottom"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="Type == 0 && PurchaseId == 0">
              个人中心
            </el-dropdown-item>
            <el-dropdown-item @click.native="userInfo" v-else>
              个人中心
            </el-dropdown-item>
            <el-dropdown-item @click.native="editPassword">修改密码</el-dropdown-item>
            <el-dropdown-item @click.native="signout"
            >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="notice-list">
      <el-dialog :visible.sync="dialogVisible">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">消息列表</span>
        </div>
<!--        <template>-->
        <div style="height: 400px" class="content">
          <el-table
            :data="tableData"
            max-height="400px"
            style="width: 100%"
            :header-cell-style="{
                color: '#333333',
                backgroundColor: '#FBFBFD',
              }"
            row-key="id"
            border
          >
            <el-table-column prop="SendUserName" label="创建人" width="120" align="center">
            </el-table-column>
            <el-table-column prop="MsgName" label="标题" width="180" align="center">
            </el-table-column>
            <el-table-column prop="CreateDate" label="发送时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="LookFlag" label="是否已查看" align="center">
            </el-table-column>
            <el-table-column
              label="下载附件"
              align="center"
              width="120">
              <template slot-scope="scope">
                <span @click="seeFile(scope.row)"  style="color: #3978E7;font-weight: 400" v-show="scope.row.TopicId == 0">附件</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link
                  style="font-size:16px;color: #3978E7;font-weight: 400"
                  type="text"
                  :underline="false"
                  @click="handleEdit(scope.$index, scope.row)"
                ><i class="el-icon-view"></i>查看</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
<!--        </template>-->
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="num"
            :page-size="13"
            layout="total,prev, pager, next, jumper"
            :total="PageCount">
          </el-pagination>
        </div>
      </el-dialog>
    </div>
    <div class="notice-details">
      <el-dialog
        top="25vh"
        :visible.sync="handleEditDialog"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">消息详情</span>
        </div>
        <el-form  label-width="90px" :model="tableDetailed">
          <el-form-item label="标题:">
            <el-input type="textarea" v-model="tableDetailed.MsgName" readonly ></el-input>
          </el-form-item>
          <el-form-item label="创建人:">
            <el-input v-model="tableDetailed.SendUserName" readonly style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item label="接收人:">
            <el-input v-model="tableDetailed.ReceiveUserName" readonly ></el-input>
          </el-form-item>
          <el-form-item label="创建时间:">
            <el-input v-model="tableDetailed.CreateDate" readonly style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item label="内容:">
            <el-input type="textarea" v-model="tableDetailed.MsgText" readonly ></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div class="dialog_add">
      <el-dialog
        :visible.sync="userEditDialog"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑</span>
        </div>
          <user-info @userInfo-edit="userInfoEdit"></user-info>
      </el-dialog>
    </div>
    <div class="edit-password">
      <el-dialog
        top="25vh"
        :visible.sync="editPasswordDialog"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改密码</span>
        </div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="原始密码" prop="pwd">
            <el-input type="password" v-model="ruleForm.pwd" autocomplete="off" show-password placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="pass">
            <el-input type="password" v-model="ruleForm.pass" autocomplete="off" show-password placeholder="请输入6到20位之间的密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"  show-password placeholder="请输入6到20位之间的密码"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="btn">
              <button  @click="editPasswordDialog = false">取消</button>
              <button  @click="submitForm('ruleForm')">确定</button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import UserInfo from "@/components/UserInfo";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import research from "../api/research";
import { mapState } from "vuex";
import reseacrh from "@/api/research";
import {downloadFileId, NoticeFileId} from "@/utils/downloadUrl";
import api from "../api/Grantsandmilestones"
export default {
  components: {
    UserInfo
  },
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  data(){
    var validatePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return{
      PageCount: 0,
      currentPage: 1, // 分页参数
      userEditDialog: false,
      PurchaseId: window.sessionStorage.getItem('PurchaseId'),
      Type: window.sessionStorage.getItem('Type'),
      UserName: window.sessionStorage.getItem('UserName'),
      show:1,
      tableDetailed: {},
      handleEditDialog: false,
      //是否显示设置信息
      val: 0,
      message: false,
      dialogVisible: false,
      tableData: [],
      num: 1,
      editPasswordDialog:false,
      ruleForm: {
        pwd: '',
        pass: '',
        checkPass: '',
      },
      arr:[],
      project:true,
      task:true,
      Finance:true,
      rules: {
        pwd: [
          { validator: validatePwd , trigger: 'blur' },
          {min:1, max:20, message: '密码不能为空'}
        ],
        pass: [
          { validator: validatePass, trigger: 'blur' },
          {min:6, max:20, message: '密码在6到20位之间'}
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' },
          {min:6, max:20, message: '密码在6到20位之间'}
        ]
      }
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 5) {
        return value.slice(0, 5) + "...";
      }
      return value;
    },
  },
  computed: mapState(["menuindex","noticeList","projectId"]),
  created(){
    if(this.projectId!=null){
      api.MenuAllData(this.projectId).then(res=>{
           
          console.log(res,"%%%%%%%%%%");
            this.arr=res.data.ExtraData.split(",")
            if(this.arr[0]==0){
                this.project=false
            }else{
               this.project=true
            }
            if(this.arr[1]==0){
                this.task=false
            }else{
              this.task=true
            }
            if(this.arr[2]==0){
               this.Finance=false
            }else{
               this.Finance=true
            }
      })
    }

    research.nummsg().then((res) => {
      this.val = res.data;
    });
    if(sessionStorage.getItem("menuindex")){
      this.$store.commit("menustate", sessionStorage.getItem("menuindex"));
    }else{
      this.$store.commit("menustate", 1);
    }
  },
  methods:{
    Switchingsystem(){
         
    },
    seeFile(item) { // 查看附件  除图片除外
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        // console.log(res)
        if (res.data.Status==1) {
          if (res.data.ExtraData.length == 0) {
            this.$message.warning('该通知公告没有上传附件')
            return false
          }
          res.data.ExtraData.forEach( items => {
            this.downloadfile(items.Id,item.Id)
          })
        }
      })
    },
    handleCurrentChange(val){ // 分页
      this.num=val
      this.msg()
      console.log(`当前页: ${val}`);
    },
    userInfoEdit(data) {
        this.userEditDialog = data
    },
    userInfo() {
      this.userEditDialog = true
    },
    signout() {
      this.$router.push({ name: "login" });
      window.sessionStorage.clear()
    },
    editPassword() { //修改密码
      this.editPasswordDialog = true
    },
    submitForm(formName) { //提交密码
      this.$refs[formName].validate((valid) => {
        var formData = {}
        formData.OldPassword = this.ruleForm.pwd
        formData.NewPassword = this.ruleForm.pass
        if (valid) {
          research.editPwd(formData).then(res => {
            if (res.data.Status ==1) {
              this.editPasswordDialog = false
              this.$message({
                message: '密码修改成功',
                type: 'success'
              });
              // this.$router.push('/')
            }else {
              this.$message.error(res.data.Message)
            }
          })
        } else {
          this.$message('提交失败')
          return false;
        }
      });
    },
    changeFontSize(size){
      window.document.documentElement.setAttribute("data-size", size);
    },
    flaglist(stringname,num,routename){//判断能否进入项目菜单和财务管理菜单
       let parame={
         topicId:this.projectId,
         menu:stringname
       }
       api.judgeData(parame).then(res=>{
          if(res.data.Status==1){
            this.$store.commit("menustate", num);
            this.$store.commit("showflag", false);
            this.$router.push({name:routename})
          }else{
             this.$message({
              message: res.data.Message,
              type: 'warning'
            });
          }
       })
    },
    MenuAlllist(){
      api.MenuAllData(this.projectId).then(res=>{
        console.log(res,"@@@@@@@@@");
        if(res.data.Status==1){
          if(res.data.ExtraData=="0,0"){
             this.$message({
              message: "您没有操作权限!",
              type: 'warning'
            });
          }else{
            window.sessionStorage.setItem("midshow",res.data.ExtraData)
            this.$store.commit("menustate", 5);
            this.$store.commit("showflag", false);
            this.$router.push({name:"ArchivesFinance"})
          }
        //  this.$store.commit("midshow", res.data.ExtraData);
        }else{
          alert(res.data.Message)
        }
      })
    },
    aa(){
      this.$store.commit("menustate", 1);
      this.$store.commit("showflag", false);
      this.$router.push({name:"HomePage"})
    },
    bb(){
      this.flaglist("project",2,"ProjectManagement")
    },
    cc(){
      let parame={
         topicId:this.projectId,
         menu:"project"
       }
       api.judgeData(parame).then(res=>{
         console.log(res,"成功没");
          if(res.data.Status==1){
              this.$store.commit("menustate", 3);
              this.$store.commit("showflag", false);
              this.$router.push({name:"TaskManagement"})
              this.$store.commit("midshow",false)
          }else{
              this.$store.commit("menustate", 3);
              this.$store.commit("showflag", false);
              this.$router.push({name:"TaskManagement"})
              this.$store.commit("midshow",true)
          }
       })
    },
    dd(){
      this.flaglist("financ",4,"FinancialManagement")
    },
    ff(){
      this.MenuAlllist()
      // this.$store.commit("menustate", 5);
      // this.$store.commit("showflag", false);
      // this.$router.push({name:"ArchivesFinance"})
    },
    ee(){
      this.$store.commit("menustate", 6);
      this.$store.commit("showflag", false);
      this.$router.push({name:"System"})
    },
    hh() {
      this.$store.commit("menustate", 7);
      this.$store.commit("showflag", false);
      this.$router.push({name:"ProjectManagementPage"})
    },
    setting() { // 管理员的维护
      this.$router.push('/navber')
    },
    loadMoreData() {
      this.num++;
      this.msg();
      console.log(this.tableData);
    },
    msg1() {
      this.dialogVisible = true;
      this.msg()
    },
    msg() {
      var queobj = {
        TopicId: "",
        PageIndex: this.num-1,
        PageSize: 13,
        SysFlag:-1
      };
      research.querymsg(queobj).then((res) => {
        // console.log(res,'分页')
        res.data.Entity.forEach((item) => {
          if (item.LookFlag == 0) {
            item.LookFlag = "未查看";
          }
          if (item.LookFlag == 1) {
            item.LookFlag = "已查看";
          }
        });

        this.tableData = res.data.Entity;
        this.PageCount = res.data.TotalRowsCount
      });
    },
    handleEdit(i, item) {
      this.tableDetailed = item
      research.getmsg(item.Id).then((res) => {
        if (res.data.Status == 1) {
          research.nummsg().then((res) => {
            this.val = res.data;
            this.msg()
            // console.log(res.data, "消息提醒");
          });
          this.handleEditDialog = true
        }
      });
    },
    downloadfile(DataId,MessageId){
      // downloadFileId(id)
      NoticeFileId(DataId,MessageId)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../styles/mixin.scss";
.headassembly{
  width: 1920px;
  height: 100%;
  .headassembly_left{
    width: 1220px;
    height: 100%;
    float: left;
    //box-shadow: 0 4px 0 #909399;
    box-shadow: 0px 3px 0px rgba(0, 104, 163, 0.1);
    h2{
      width: 450px;
      height: 100%;
      float: left;
      display:table;
      img {
        margin-left: 22px;
        margin-top: 12px;
        margin-right: -10px;
        vertical-align: middle;
      }
      p{
        display:table-cell;
        //text-align:center;
        //text-align: left;
        vertical-align: middle;
        font-size: 26px;
        color: #333333;
        line-height: 40px;
      }
    }
    .headassembly_div{
      width: 770px;
      height: 100%;
      float: left;
      ul{
        width: 770px;
        height: 100%;
        overflow: hidden;
        li{
          width: 100px;
          height: 80%;
          text-align: center;
          float: left;
          border-radius: 4px;
          margin-top: 6px;
          img{
            width: 24px;
            height: 24px;
            margin-top: 6px;
          }
          p{
            font-size: 16px;
            color: #69696A;
          }
        }
      }
    }
  }
  .headassembly_right{
    width: 700px;
    height: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    padding-top: 23px;
    box-shadow: 0 3px 0 rgba(0, 104, 163, 0.1);
    box-sizing: border-box;
    /deep/.el-dropdown-menu {
      .el-dropdown-menu__item {
        padding: 0 10px;
      }
    }
  }
}
.headassemblybackground{
  background: linear-gradient(180deg, #5F7FFE 0%, #A5BDFF 100%);
  p{
    color: #ffffff!important;
  }
}
.notice-list {
  /deep/ .el-dialog {
    width: 903px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-top: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
  }
}
.notice-details {
  /deep/ .el-dialog {
    width: 578px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-top: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .el-form {
      .el-form-item {
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
        .el-input__inner {
          border: 0;
        }
        .el-textarea__inner {
          border: 0;
          resize: none;
        }
      }
    }
  }
}
.edit-password {
  /deep/ .el-dialog {
    width: 578px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-top: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .el-form {
      .el-form-item {
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
      }
    }
    .btn {
      margin-top: 52px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.dialog_add {
  /deep/ .el-dialog {
    width: 1200px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
  }
}
.radio-dian {
  width: 7px;
  height: 7px;
  background: #F94747;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  margin-top: 1px;
  opacity: 1;
}
.content {
  ::-webkit-scrollbar {
    width:8px!important;
    height: 8px;

  }
  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}
.block {
  margin-top: 10px;
}
.notice-details  /deep/.el-input {
  @include add-size($font_size_16);
  border: 0;
}
.notice-details  /deep/.el-textarea {
  @include add-size($font_size_16);
  border: 0;
}
.notice-list /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.notice-list /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
</style>
