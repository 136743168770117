<template>
  <div>
    <div class="dialog_edit">
        <div class="body">
          <div class="body-left">
            <el-form label-width="150px">
              <el-form-item label="登录名:">
                <el-input v-model="editForm.LoginName"></el-input>
              </el-form-item>
              <el-form-item label="密码:">
                <el-input v-model="editForm.Password"></el-input>
              </el-form-item>
              <el-form-item label="姓名:">
                <el-input v-model="editForm.UserName"></el-input>
              </el-form-item>
              <el-form-item label="手机号:">
                <el-input v-model="editForm.PhoneNumber"></el-input>
              </el-form-item>
              <el-form-item label="身份证:">
                <el-input v-model="editForm.IdentityCard"></el-input>
              </el-form-item>
              <el-form-item label="性别:">
                <el-radio-group v-model="editForm.Sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="body-right">
            <el-form label-width="150px">
              <el-form-item label="微信:">
                <el-input v-model="editForm.WX"></el-input>
              </el-form-item>
              <el-form-item label="邮箱:">
                <el-input v-model="editForm.EMail"></el-input>
              </el-form-item>
              <el-form-item label="职称:">
                <el-select v-model="editForm.Title">
                  <el-option :label="item.Name" :value="item.Name" v-for="(item,index) in TitleArray" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="医院:">
                <el-input v-model="editForm.HospitalName" disabled></el-input>
              </el-form-item>
              <el-form-item label="科室:">
                <el-input v-model="editForm.DeptName" disabled></el-input>
              </el-form-item>
              <el-form-item label="角色:">
                <el-select v-model="editForm.RoleId"  disabled>
                  <el-option
                    :label="item.RoleName"
                    :value="item.Id"
                    v-for="(item, index) in option"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="btn">
          <button  @click="handleEditDialog">取消</button>
          <button @click="submitEdit">确定</button>
        </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/url";
import research from "@/api/research";
import {getUsersInfo} from "@/api/project";

export default {
  name: "UserInfo",
  data() {
    return {
      option:[],//角色数组
      TitleArray:[],//职称数组
      editForm: { // 编辑的信息
      },
    }
  },
  created() {
    this.titlelist()
    this.allrolelist()
    this.getUserInfo()
  },
  methods: {
    handleEditDialog() {
      this.$emit('userInfo-edit', false )
    },
    async getUserInfo() { // 获取用户信息
      const parame = { UserId: window.sessionStorage.getItem("Id") };
      const res = await getUsersInfo(parame);
      if (res.data.Status !== 1) {
        return this.$message.error("查询失败");
      }
      console.log(res)
      this.editForm = res.data.Entity;
    },
    allrolelist(){//角色
      api.allroleData().then(res=>{
        if(res.data.Status==1){
          this.option=res.data.Entity
        }else{
          alert(res.data.Message)
        }
      })
    },
    titlelist(){//职称数组
      console.log('职称')
      let parame="JobTitle"
      api.titleData(parame).then(res=>{
        if(res.data.Status==1){
          this.TitleArray=res.data.Entity
        }else{
          alert(res.data.Message)
        }
      })
    },
    submitEdit() {
      // var parame={
      //   Id:this.editForm.Id,
      //   RoleId:this.editForm.RoleId,
      //   TopicId:0,
      //   UserId:this.editForm.Id
      // }
      // if (this.editForm.RoleId !== this.editRoleId ) {
      //   api.personnelupData(parame).then(res=>{
      //     if(res.data.Status==1){
      //       console.log(res)
      //     }else{
      //       alert(res.data.Message)
      //     }
      //   })
      // }
      // this.editForm.Id = this.editForm.UserId
      research.personnelupData(this.editForm).then(res => {
        if (res.data.Status ==1) {
          this.$message.success(res.data.Message)
          this.$emit('userInfo-edit', false )
          // this.jurisdictionlist()
        } else {
          this.$message.error(res.data.Message)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixin.scss";
  .dialog_edit {
    .body {
      width: 1200px;
      height: 470px;
      display: flex;
      .body-left {
        float: left;
       /deep/ .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
          }
        }
      }
      .body-right {
        float: left;
        /deep/ .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
            .el-icon-circle-plus-outline {
              width: 30px;
              display: inline-block;
              font-size: 30px;
              vertical-align: middle;
            }
            .pspan {
              display: inline-block;
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;

              .spanname {
                @include add-size($font_size_16);
                margin-left: 6px;
              }
            }
            .block {
              .el-date-editor--daterange.el-input,
              .el-date-editor--daterange.el-input__inner,
              .el-date-editor--timerange.el-input,
              .el-date-editor--timerange.el-input__inner {
                width: 390px;
              }
            }
            .el-radio__label{
              @include add-size($font_size_16);
            }
          }
        }
      }
    }
    .btn {
      margin-bottom: 14px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
</style>
